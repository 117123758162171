<template>
    <div>
        <div
            class="row justify-content-center mt-3"
        >
            <div class="col-12 col-md-8 col-lg-6">
                <label>Policy System</label>

                <Multiselect
                    v-model="policySystemValue"
                    data-cy="policySystemSelect"
                    :close-on-select="true"
                    :disabled="disabledBySearchId(ratingId)"
                    :options="policySystems"
                    :show-labels="false"
                    placeholder="select a policy system..."
                    @input="ratingSelected(policySystemValue)"
                />
            </div>
        </div>

        <div class="row justify-content-center mt-3">
            <div class="col-12 col-md-8 col-lg-6">
                <label>Date Range</label>

                <DatePicker
                    id="datePicker"
                    ref="datePicker"
                    v-model="dateRange"
                    data-cy="datePicker"
                    class="date-picker"
                    :b-form-date-picker-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
                    :clearable="!!dateRange.length && !disabledBySearchId(ratingId)"
                    :disabled="disabledBySearchId(ratingId)"
                    end-placeholder="pick an end date..."
                    placeholder="pick a start date..."
                    type="daterange"
                    @change="dateRange = $event; ratingSelected($event)"
                />
            </div>
        </div>

        <div class="row justify-content-center mt-3">
            <STSearchIdInput
                ref="searchIdInput"
                v-model="ratingId"
                :disabled="loading"
                type="rating"
                @input="ratingSelected(ratingId = $event)"
                @resetFields="resetFields"
            />
        </div>


        <div class="row justify-content-center">
            <STKeywordInput
                ref="STKeywordInput"
                v-model="keywordValue"
                :disabled="disabledBySearchId(ratingId)"
                @input="$event !== '' ? ratingSelected(keywordValue = $event, search = false) : ratingSelected(keywordValue = $event)"
                @resetFields="resetFields"
            />
        </div>

        <STSearchButtons
            @resetFields="resetFields"
        />

        <RouterView />
    </div>
</template>

<script>
    import DatePicker from '@imt/vue-kit-car/src/components/DatePicker.vue';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import {isEqual} from 'lodash';
    import Multiselect from 'vue-multiselect';
    import {mapState} from 'vuex';

    import STKeywordInput from '@/components/STKeywordInput.vue';
    import STSearchButtons from '@/components/STSearchButtons.vue';
    import STSearchIdInput from '@/components/STSearchIdInput.vue';
    import searchMixin from '@/mixins/search';

    export default {
        name: 'STRatingSearch',
        components: {
            DatePicker,
            Multiselect,
            STSearchButtons,
            STSearchIdInput,
            STKeywordInput,
        },
        mixins: [
            searchMixin,
            toastsMixin,
        ],
        data() {
            return {
                dateRange: [],
                dropdownOptions: [],
                policySystemValue: '',
                ratingId: '',
                keywordValue: '',
            };
        },
        computed: {
            policySystems() {
                return this.uniq(this.dropdownOptions.policySystem).sort();
            },
            ...mapState([
                'initialSearchSubmitted',
                'loading'
            ])
        },
        async mounted() {
            await this.setupDropdowns();
            /* istanbul ignore next */
            await this.initFromQuery();
        },
        methods: {
            async initFromQuery() {
                if (Object.values(this.$route.query).some(x => x !== null && x !== '')) {
                    this.dateRange[0] = this.$route.query.requestStart || '';
                    this.dateRange[1] = this.$route.query.requestEnd || '';
                    this.policySystemValue = this.$route.query.policySystem || '';
                    this.ratingId = this.$route.query.searchId || '';
                    this.keywordValue = this.$route.query.keyword || '';
                    this.$refs.searchIdInput.searchId = this.$route.query.searchId || '';

                    await this.submitSearch();
                }
            },
            async resetFields() {
                this.policySystemValue = '';
                this.ratingId = '';
                this.keywordValue = '';

                await this.resetSharedValues();
            },
            async ratingSelected(selectedValue, search = true) {
                this.$ga.event('Search', `${selectedValue} Selected`, 'click');

                if (this.ratingId) {
                    await this.$router.push({
                        query: {
                            searchId: this.ratingId
                        },
                    });
                } else {
                    let queryObj = {
                        policySystem: this.policySystemValue !== '' ? this.policySystemValue : '',
                        requestStart: this.dateRange[0],
                        requestEnd: this.dateRange[1],
                        search: this.keywordValue,
                    };

                    if (!isEqual(this.$route.query, queryObj)) {
                        await this.$router.push({
                            query: queryObj
                        });
                    }

                    if (search) {
                        if (this.initialSearchSubmitted) {
                            await this.submitSearch(false);
                        }

                        await this.setupDropdowns();
                    }
                }
            },
        },
    };
</script>
